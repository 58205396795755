h1, .header__h1{

}

h2, .header__h2{
    font-size: 32px;
    color: $secondary-color;
    letter-spacing: 1px;
    text-align: left;
    span{
      color: $primary-color;
    }
  &:after{
    background: $primary-color;
    width:55px;
    height:4px;
    display: inline-block;
    position: relative;
    content: '';
    top:3px;
    left:15px;
  }
}

h3, .header__h3{
  font-weight: 100;
  font-size: 25px;
}

h4, .header__h4{
  color: $primary-color;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 30px;
  position: relative;
  &:after{
    background: $primary-color;
    width:55px;
    height:4px;
    display: inline-block;
    position: absolute;
    content: '';
    top:70px;
    left:0px;
  }
}

h5, .header__h5{

}

h6, .header__h6{

}
