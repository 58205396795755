.button{
  &--primary{
    &--full{
      background-color: $primary-color;
      width: 100%;
      display: inline-block;
      color:$white;
      padding: 20px;
      box-sizing: border-box;
      &:hover{
        background-color: $primary-color-light;
        color:$white;
      }
    }
  }
}
