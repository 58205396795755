.statistics{
  table{
    width: 100%;
    *{
      transition: all .2s;
    }
    tr{

      border:3px solid $primary-color;
      &:nth-of-type(1){

        td{
          background: $primary-color;
          color:#fff;
          font-size: 24px;
          font-weight: 800;
          @include mobile{
          font-size: 16px
          }
          @include tablet{
          font-size: 16px
          }


        }
        td:nth-of-type(1){
          background: url(../_gfx/new-logo-dark.png) no-repeat center center;;
          border:none;
          background-size: 80% auto ;
        }
      }
      td{
        border:3px solid $primary-color;
        font-size: 18px;
        padding: 15px 0;
        color: $primary-color;
        @include mobile{
          padding: 8px 0;
          font-size: 14px;
        }
        @include tablet{
          padding: 8px 0;
          font-size: 14px;
        }

        &:nth-of-type(1){
          width: 20%;
          font-size: 24px;
          font-weight: 800;
          color:#fff;
          background: $primary-color;
          @include mobile{
          font-size: 16px;
          width:33.333%;
          }
          @include tablet{
          font-size: 16px;
          width:33.333%;
          }

        }
      }
      &:hover{
        opacity:.6;
      }
    }
  }
}
