 .news{
   &__overlay{
     position: absolute;
     top:0;
     left:0;
     right:0;
     bottom: 0;
     /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e5e5e5+0,e5e5e5+57&0.06+0,1+59 */
background: -moz-linear-gradient(top, rgba(229,229,229,0.06) 0%, rgba(229,229,229,0.97) 57%, rgba(229,229,229,1) 59%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(229,229,229,0.06) 0%,rgba(229,229,229,0.97) 57%,rgba(229,229,229,1) 59%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(229,229,229,0.06) 0%,rgba(229,229,229,0.97) 57%,rgba(229,229,229,1) 59%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0fe5e5e5', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */
    &--text{
      position: relative;
      z-index: 1;
      margin-top:188px;
    }
   }
   &__article{
     &__data{
       text-align: right;
        width: 100%;
        display: inline-block;
     }
   }
 }
