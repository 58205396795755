.column{

  &--decorated{
    border: $border;
    background-color: $decorated-column-bg;
    padding: 0 22px;
    text-align: left;
    background-size: 100% ;
    background-position:  center 100px;
    background-repeat: no-repeat;
    position: relative;
    height: 467px;
    overflow: hidden;
  }
}

.movie-icon{
  position: relative;
  display: inline-block;
  &:before{
    content: '';
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    background: url(../_gfx/movie1.png);
    width:52px;
    height:37px;
    z-index: 2;
  }
  &:after{
    content: '';
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%) rotateZ(0deg);
        background: url(../_gfx/movie2.png);
    width:58px;
    height:57px;
    z-index: 3;
    transition: all .3s;

  }
}
.movie-icon:hover{
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
    background: url(../_gfx/movie1.png);
    width:52px;
    height:37px;
    z-index: 2;
  }
  &:after{
    content: '';
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%) rotateZ(180deg);
    background: url(../_gfx/movie2.png);
    width:58px;
    height:57px;
    z-index: 3;


  }
}
