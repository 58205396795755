.transformation{
  &__header{
    display: inline-block;
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    color: $white !important;
    background-color: $primary-color;
    margin-bottom: 0;
  }
}
