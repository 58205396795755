.animation{
  &__image{
    img{
      transition: all .2s;
      transform: translate(-50%, -50%) scale(1);
    }
    &:hover img{
      transform: translate(-50%, -50%) scale(1.1);
      opacity: .6;
    }
  }
  &__column{
    transition: all .2s;
    &:hover{
    }
  }
}
