.align{
  &--left{
    text-align: left;
  }
  &--right{
    text-align: right;
  }
  &--center{
    text-align: center;
  }
  &--justify{
    text-align: justify;
  }
}
