$primary-color: #ff9600;
$primary-color-light: lighhten($primary-color, 10%);
$primary-color-dark: darken($primary-color, 10%);
$secondary-color: #1a171b;
$secondary-color-light: lighhten($secondary-color, 10%);
$secondary-color-dark: darken($secondary-color, 10%);


$white: #ffffff;
$black: #000000;

$gray-section-color: #e5e5e5;
$decorated-column-bg: #e5e5e5;

/* padding */
$padding: 0 50px;
$column-padding: 0 22px;

$grid-columns: 12;
$offset-steps: 20;
$header-zindex: 1000;

$border: solid 1px $primary-color;

$std-padding: 8px 14px;
